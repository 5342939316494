.container {
  direction: rtl;
  /* border: green 1px solid; */
  margin: 5px;
}

.row {
  /* border: blue 1px solid; */
  margin: 5px;
  margin-bottom: 40px;
}

.col {
  /* border: orange 1px solid; */
  margin: 5px;
  text-align: center;
}

.page-header {
  text-align: center;
  background-color: rgb(18, 140, 126);
  color: white;
}

.page-footer {
  direction: ltr;
}
